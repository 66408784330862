import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroBlogPage from "../components/pages/shared/hero/blog-page"
import { graphql, StaticQuery } from "gatsby"
import { mapToPost } from "../blog/components/blog-utils"
import BlogPostCard from "../blog/components/blog-post-card"
import BlogPostGrid from "../blog/components/blog-post-grid"
import ContactFormComponent from "../components/pages/shared/contact-form"
import Breadcrumb from '../components/controls/breadcrumbs';

const IndexPage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Блог Параплан', url: ''}
  ];
  return (
    <Layout page={"blog"} headerColor={"#03C4A4"}>
      <SEO
        title="Блог"
        description="Блог CRM Параплан - рассказываем, как быстро внедрить CRM-систему и использовать ее по-максимуму в детских и учебных центрах, сетях и филиалах, языковых школах, танцевальных студиях, детских клубах и др. Делимся экспертными советами по ведению образовательного бизнеса."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroBlogPage/>
      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <StaticQuery query={blogPostsQuery} render={
        data => {
          const { edges } = data.allMarkdownRemark
          return <BlogPostGrid>
            {
              edges.map((edge, index) => {
                const post = mapToPost(edge)
                return <BlogPostCard key={post.path}
                                     post={post}
                                     lead={index === 0}/>
              })
            }
          </BlogPostGrid>
        }
      }/>
      <ContactFormComponent/>
    </Layout>
  )
}

export const blogPostsQuery = graphql`
  query BlogPostsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {fileAbsolutePath: {regex: "/src/blog/"}}
    ) {
      edges {
        node {
          frontmatter {
            cover {
              childImageSharp {
                sm: fluid(maxWidth: 470) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                mob: fluid(maxWidth: 835) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            lead {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            excerpt
            path
            title
          }
          timeToRead
        }
      }
    }
  }
`

export default IndexPage
